<template>
  <div>
    <a-form-model
        ref="search_form"
        :model="search_form"
        layout="inline"
    >
      <a-row style="padding-bottom: 15px">
        <a-col :span="8">
          <a-form-model-item
              label="摄影"
          >
            <a-input
                placeholder="请输入"
                v-model="search_form.workerName"
                style="width: 260px;"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
              label="摄影日期"
          >
            <a-range-picker
                v-model="time"
                :placeholder="['开始日期', '结束日期']"
                format="YYYY-MM-DD"
                inputReadOnly
                :disabled-date="disabledPriceRangeDate"
                @calendarChange="calendarPriceRangeChange"
                @change="onChangeDate(arguments, 'photoDate')"
                style="width: 230px"
            />
            <p style="line-height: 18px">
              <span style="font-size: 16px;color: red;padding-right: 3px">*</span>
              时间间隔不能超过30天
            </p>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-button class="mr-10" @click="onClickButton('reset')">重置</a-button>
          <a-button type="primary" class="mr-10" @click="onClickButton('search')">查询</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <a-table
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :rowKey="(record, index) => index"
        :loading="loading"
        bordered
        :scroll="{x:1000}"
        class="a-tables"
    >
      <div slot="date" slot-scope="text">
        <div v-if="((text || {}).dateListVOList || []).length">
          <p v-for="(item) in ((text || {}).dateListVOList || [])">
            {{ item.workerName }}
            <span v-if="item.workerName">: </span>
            {{ item.total }}
            <span
                style="color: #40a9ff;cursor: pointer;padding-left: 8px"
                @click="openModal(item)"
            >
            [查看]
          </span>
          </p>
        </div>
      </div>
      <template slot="footer" v-if="footerData.length">
        <ATable
            :columns="columns"
            :pagination="false"
            :showHeader="false"
            :rowKey="(record, index) => index"
            :scroll="{x:1000}"
            :loading="loading"
            bordered
            :data-source="footerData"
            class="a-tables-footer"
        />
      </template>
    </a-table>

    <!--查看排期-->
    <a-modal v-model="photographyModal" :title="'查看'" :footer="null" width="70%">
      <a-row style="padding-bottom: 25px">
        <a-col :span="6">
          <span>拍摄日期：{{ rowData.photoDate || "" }}</span>
        </a-col>
        <a-col :span="6">
          <span>品 牌：{{ rowData.principalName || "" }}</span>
        </a-col>
        <a-col :span="6">
          <span>摄 影：{{ rowData.workerName || "" }}</span>
        </a-col>
      </a-row>
      <div>
        <a-table
            :columns="modalColumns"
            :data-source="modalList"
            :pagination="false"
            :rowKey="(record) => record.id"
            :loading="modalLoading"
            bordered
            :scroll="{ y: 400,x:900 }"
        >
          <div slot="names" slot-scope="text, record">
            <a-tooltip>
              <template #title>{{ record.scriptName }}</template>
              <p style="color: #40a9ff;cursor: pointer" @click="openRemarkUrl('script',record.scriptAttachUrl)">
                {{ record.scriptName || "-" }}</p>
            </a-tooltip>
          </div>
          <div slot="remarkUrl" slot-scope="text, record">
            <div class="photography-img" @click="openRemarkUrl('remarkUrl',record.remarkUrl)">
              <img :src="record.remarkUrl" v-if="record.remarkUrl" alt="摄影批注">
              <a-icon v-else type="picture" style="font-size: 30px;padding-left: 6px;padding-top: 25px"/>
            </div>
          </div>
        </a-table>

        <!-- 分页配置 -->
        <base-pagination
            :currentPage="pagination.page_num"
            :pageSize="pagination.page_size"
            :total="pagination.total"
            :options="['10', '30', '50']"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        >
        </base-pagination>
      </div>
    </a-modal>

    <!--查看图片-->
    <preview-modal
        :visible="preview_visible"
        :preview-src="preview_src"
        width="50%"
        @cancel="handlePreviewCancel"
    />

    <!--查看脚本-->
    <preview-modal
        :visible="preview_visible_script"
        :preview-src="preview_src_script"
        width="80%"
        @cancel="handlePreviewCancel"
    />
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/api/contentProduction.js";
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";

export default {
  name: "photography_list",
  data() {
    return {
      columns: [
        {
          dataIndex: "principalName",
          width: 120,
          title: "品牌",
          key: "principalName",
          fixed: "left"
        },
        {
          dataIndex: "total",
          width: 100,
          title: "总计",
          key: "total",
          fixed: "left"
        }
      ],
      list: [],
      loading: false,
      footerData: [],
      search_form: {
        startDate: moment().weekday(0).format("YYYY-MM-DD"),
        endDate: moment().weekday(6).format("YYYY-MM-DD")
      },
      time: [
        moment().weekday(0).format("YYYY-MM-DD"),
        moment().weekday(6).format("YYYY-MM-DD")
      ],
      selectPriceDate: "",
      offsetDays: 29, //最多选择范围31天m
      photographyModal: false,
      modalColumns: [
        {
          scopedSlots: {customRender: "names"},
          title: "脚本名称"
        },
        {
          dataIndex: "seriesName",
          width: 140,
          title: "车系"
        },
        {
          scopedSlots: {customRender: "remarkUrl"},
          width: 100,
          title: "摄影批注"
        },
        {
          dataIndex: "photoAddr",
          width: 180,
          title: "摄影场地",
          ellipsis: true
        }
      ],
      modalList: [],
      modalLoading: false,
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      rowData: {},
      preview_visible: false,
      preview_src: "",
      preview_visible_script: false,
      preview_src_script: "",
    };
  },
  components: {previewModal},
  mounted() {
    this.getScheduleList();
    console.log(this.search_form.startDate);
  },
  methods: {
    moment,
    getScheduleList() {
      this.loading = true;
      let params = {
        workerName: this.search_form.workerName,
        startDate: this.search_form.startDate,
        endDate: this.search_form.endDate
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getScheduleList(params).then(res => {
        if(res.code == 200) {
          const {principalCountList, principalTotal} = res?.data || {};
          if(principalTotal) {
            this.footerData = [{principalName: "总计", total: principalTotal || 0}];
          }
          const List = principalCountList?.map((item, index) => {
            const principalDateCountList = item.principalDateCountList || [];
            if(index === 0) {
              const columns = principalDateCountList.map(child => {
                const {photoDate} = child;
                return {
                  dataIndex: photoDate,
                  scopedSlots: {customRender: "date"},
                  key: photoDate,
                  title: moment(photoDate).format("MM月DD日"),
                  width: 300
                };
              });

              this.columns = [
                {
                  dataIndex: "principalName",
                  width: 120,
                  title: "品牌",
                  key: "principalName",
                  fixed: "left"
                },
                {
                  dataIndex: "total",
                  width: 100,
                  title: "总计",
                  key: "total",
                  fixed: "left"
                },
                ...columns
              ];
            }
            principalDateCountList.forEach(child => {
              item[child.photoDate] = child;
            });
            return item;
          });
          this.list = List || [];
        } else {
          this.list = [];
          this.footerData = [];
          this.$message.error(res.message || "获取摄影排期列表失败");
        }
        this.loading = false;
      });
    }, // 摄影列表
    getScheduleDetailList() {
      this.modalLoading = true;
      console.log(this.rowData);
      let params = {
        principalId: this.rowData.principalId,
        workerIds: this.rowData.workerId,
        workerIdList: this.rowData.workerName,
        photoDate: this.rowData.photoDate,
        page: this.pagination.page_num,
        size: this.pagination.page_size,
      };
      _api.getScheduleDetailList(params).then(res => {
        if(res.code == 200) {
          this.photographyModal = true;
          this.modalList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.modalList = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "查看详情列表失败");
        }
        this.modalLoading = false;
      });
    }, // 查看详情列表
    calendarPriceRangeChange(date) {
      this.selectPriceDate = date[0];
    },
    disabledPriceRangeDate(current) {
      const {selectPriceDate, offsetDays} = this;
      if(selectPriceDate) {
        const start = moment(selectPriceDate).subtract(offsetDays, "d").valueOf();
        const end = moment(selectPriceDate).add(offsetDays, "d").valueOf();
        return current < start || current > end;
      } else {
        return false;
      }
    },
    onClickButton(type) {
      switch(type) {
        case "reset":
          this.search_form = {
            startDate: moment().weekday(0).format("YYYY-MM-DD"),
            endDate: moment().weekday(6).format("YYYY-MM-DD")
          };
          this.time = [
            moment().weekday(0).format("YYYY-MM-DD"),
            moment().weekday(6).format("YYYY-MM-DD")
          ];
          this.getScheduleList();
          break;
        case "search":
          this.columns.splice(this.columns.length - 1, 0, {
            title: "数据传输完整率",
            scopedSlots: {customRender: "date"},
            width: 200
          });
          this.getScheduleList();
          break;
      }
    }, // 按钮
    onChangeDate(dates, type) {
      this.selectPriceDate = "";
      let start_time = "";
      let end_time = "";
      const timeArray = dates[1];
      if(timeArray) {
        start_time = timeArray[0] ? this.$moment(timeArray[0]).format("YYYY-MM-DD") : "";
        end_time = timeArray[1] ? this.$moment(timeArray[1]).format("YYYY-MM-DD") : "";
      }
      switch(type) {
        case "photoDate":
          this.search_form.startDate = start_time;
          this.search_form.endDate = end_time;
          break;
      }
    }, // 日期选择框
    openModal(row) {
      console.log(row);
      this.rowData = row || {};
      this.getScheduleDetailList();
    },//查看
    handlePaginationChange(current, pageSize) {
      this.pagination.page_num = current;
      this.pagination.page_size = pageSize;
      this.getScheduleDetailList();
    },
    openRemarkUrl(type, url) {
      if(!url) {
        this.$message.error("URL为空，不支持查看！");
        return;
      }
      switch(type) {
        case "script":
          let str = url.split("?")[0];
          const isWX = str.split("/")[2];
          const isImg = str.split(".")[3];
          if(isImg && isImg == "webp") {
            this.preview_visible_script = true;
            this.preview_src_script = url || "";
          } else if(isWX && isWX == "doc.weixin.qq.com") {
            window.open(url, "_target");
          } else {
            let URL = encodeURIComponent(url);
            window.open(`http://view.officeapps.live.com/op/view.aspx?src=${ URL }`, "_target");
          }
          break;
        case "remarkUrl":
          this.width = "480px";
          this.preview_visible = true;
          this.preview_src = url;
          break;
      }
    },// 查看
    handlePreviewCancel() {
      this.preview_src = "";
      this.preview_visible = false;
      this.preview_visible_script = false;
      this.preview_src_script = "";
    },//关闭成片/脚本预览
  }
};
</script>

<style lang="less" scoped>
.mr-10 {
  margin-right: 10px;
}

.photography-img {
  //width: 80px;
  //height: 80px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.a-tables /deep/ .ant-table.ant-table-bordered .ant-table-footer {
  border: none;
  padding: 0 !important;
  font-weight: bold;
}

.a-tables-footer /deep/ .ant-table-row-cell-break-word {
  background: #f9f9f9;
}
</style>
