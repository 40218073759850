<template>
  <a-card>
    <AAnchor class="p-anchor">
      <a-tabs v-model="activeKey" @change="handelTabs">
        <a-tab-pane key="1" tab="脚本">
        </a-tab-pane>
        <a-tab-pane key="2" tab="成片">
        </a-tab-pane>
        <a-tab-pane key="3" tab="模板">
        </a-tab-pane>
        <a-tab-pane key="4" tab="摄影排期">
        </a-tab-pane>
      </a-tabs>
    </AAnchor>
    <templateList
      v-if="activeKey == 1"
      :key="list_key"
      :columns="scriptColumns"
      :content-list="list"
      :loading="loading"
      :pagination="pagination"
      :list-name="'script'"
      :status-list="statusList"
      :is-show="1"
      :principal-list="principal_list"
      :scroll="{y: 'calc(100vh - 300px)', x: 1000}"
      @pagination-change="paginationChange"
      @loaded="loadList"
      @loadedList="loadedList"
    />
    <templateList
      v-if="activeKey == 2"
      :key="list_key"
      :columns="productColumns"
      :content-list="list"
      :loading="loading"
      :pagination="pagination"
      :list-name="'product'"
      :status-list="productStatusList"
      :is-show="2"
      :principal-list="principal_list"
      :scroll="{y: 'calc(100vh - 300px)', x: 1000}"
      @pagination-change="paginationChange"
      @loaded="loadList"
      @loadedList="loadedList"
    />
    <templateList
      v-if="activeKey == 3"
      :key="list_key"
      :columns="templateColumns"
      :content-list="list"
      :loading="loading"
      :pagination="pagination"
      :list-name="'template'"
      :status-list="templateStatusList"
      :is-show="3"
      :principal-list="principal_list"
      :scroll="{y: 'calc(100vh - 300px)', x: 1000}"
      @pagination-change="paginationChange"
      @loaded="loadList"
      @loadedList="loadedList"
    />
    <photographyList v-if="activeKey == 4" />
  </a-card>
</template>

<script>

import _api from "@/api/contentProduction.js";
import templateList from "./components/template_list";
import photographyList from "./components/photography_list";

export default {
  name: "TaskManagementList",
  data() {
    return {
      list_key: new Date().getTime(),
      scriptColumns: [
        {
          dataIndex: "scriptCode",
          width: 80,
          title: "编号"
        },
        {
          scopedSlots: {customRender: "status"},
          width: 130,
          title: "状态"
        },
        {
          dataIndex: "principalName",
          width: 120,
          title: "品牌"
        },
        {
          dataIndex: "seriesName",
          width: 140,
          title: "车系"
        },
        {
          dataIndex: "scriptType",
          width: 100,
          title: "脚本类型"
        },
        {
          dataIndex: "directorName",
          width: 100,
          title: "编导"
        },
        {
          dataIndex: "scriptDate",
          width: 120,
          title: "脚本日期"
        },
        {
          scopedSlots: {customRender: "difficulty"},
          width: 80,
          title: "难度"
        },
        {
          scopedSlots: {customRender: "names"},
          width: 200,
          title: "脚本名称",
          ellipsis: true,
        },
        {
          dataIndex: "suggestion",
          title: "最新修改意见",
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
          width: 100,
          fixed: "right",
          title: "操作"
        }
      ],
      productColumns: [
        {
          dataIndex: "productCode",
          width: 80,
          title: "编号"
        },
        {
          scopedSlots: {customRender: "productStatus"},
          width: 130,
          title: "状态"
        },
        {
          scopedSlots: {customRender: "names"},
          width: 200,
          title: "脚本名称",
          ellipsis: true,
        },
        {
          dataIndex: "seriesName",
          width: 120,
          title: "车系"
        },
        {
          dataIndex: "principalName",
          width: 100,
          title: "品牌"
        },
        {
          dataIndex: "productType",
          width: 90,
          title: "成片类型"
        },
        {
          dataIndex: "photographyName",
          width: 200,
          title: "摄影"
        },
        {
          dataIndex: "photoDate",
          width: 120,
          title: "拍摄日期"
        },
        {
          dataIndex: "editorName",
          width: 100,
          title: "剪辑"
        },
        {
          dataIndex: "editDate",
          width: 120,
          title: "剪辑日期"
        },
        {
          scopedSlots: {customRender: "version"},
          width: 100,
          title: "成片版本"
        },
        {
          dataIndex: "suggestion",
          title: "最新修改意见",
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
          width: 100,
          fixed: "right",
          title: "操作"
        }
      ],
      templateColumns: [
        {
          dataIndex: "guideshootCode",
          width: 80,
          title: "编号"
        },
        {
          scopedSlots: {customRender: "guideshootStatus"},
          width: 130,
          title: "状态"
        },
        {
          dataIndex: "principalName",
          width: 120,
          title: "品牌"
        },
        {
          dataIndex: "guideshootName",
          width: 120,
          title: "模板名称",
          ellipsis: true,
        },
        {
          dataIndex: "fhlGuideshootId",
          width: 160,
          title: "模板ID"
        },
        {
          dataIndex: "uploadTotal",
          width: 100,
          title: "上传次数"
        },
        {
          dataIndex: "uploadDate",
          width: 120,
          title: "上传日期"
        },
        {
          dataIndex: "testDate",
          width: 120,
          title: "测试日期"
        },
        {
          dataIndex: "groupNames",
          width: 260,
          title: "可见分组",
          ellipsis: true,
        },
        {
          dataIndex: "fhlGuideshootStatus",
          scopedSlots: {customRender: "fhlGuideshootStatus"},
          width: 100,
          title: "上架状态"
        },
        {
          dataIndex: "releaseDate",
          width: 120,
          title: "完成日期"
        },
        {
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
          width: 100,
          fixed: "right",
          title: "操作"
        }
      ],
      activeKey: "1",
      list: [],
      loading: false,
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      statusList: [
        {
          color: "#ff4446",
          name: "待分配",
          id: 1
        },
        {
          color: "#2A90F4",
          name: "策划中",
          id: 2
        },
        {
          color: "#2A90F4",
          name: "内审中",
          id: 3
        },
        {
          color: "#2A90F4",
          name: "修改中",
          id: 4
        },
        {
          color: "#ff4446",
          name: "待定稿",
          id: 5
        },
        {
          color: "#0bc9b9",
          name: "已完成",
          id: 6
        },
        {
          color: "#666",
          name: "备用/待定",
          id: 7
        },
        {
          color: "#666",
          name: "内审退回",
          id: 8
        },
        {
          color: "#666",
          name: "客审退回",
          id: 9
        },
      ],
      productStatusList: [
        {
          color: "#ff4446",
          name: "待分配",
          id: 1
        },
        {
          color: "#2A90F4",
          name: "拍摄中",
          id: 2
        },
        {
          color: "#ff4446",
          name: "待剪辑",
          id: 3
        },
        {
          color: "#2A90F4",
          name: "剪辑中",
          id: 4
        },
        {
          color: "#2A90F4",
          name: "修改中",
          id: 5
        },
        {
          color: "#2A90F4",
          name: "内审中",
          id: 6
        },
        {
          color: "#ff4446",
          name: "待审核",
          id: 7
        },
        {
          color: "#0bc9b9",
          name: "已完成",
          id: 8
        },
        {
          color: "#666",
          name: "客审退回",
          id: 9
        },
      ],
      templateStatusList: [
        {
          color: "#ff4446",
          name: "待上传",
          id: 1
        },
        {
          color: "#ff4446",
          name: "待调整",
          id: 2
        },
        {
          color: "#2A90F4",
          name: "测试中",
          id: 3
        },
        {
          color: "#ff4446",
          name: "待上线",
          id: 4
        },
        {
          color: "#0bc9b9",
          name: "已完成",
          id: 5
        },
        {
          color: "#666",
          name: "暂不上线",
          id: 6
        },
        {
          color: "#2A90F4",
          name: "修改中",
          id: 7
        },
      ],
      principal_list: [],
      search_form: {}
    };
  },
  components: {templateList, photographyList},
  mounted() {
    this.getScriptList();
    this.getPrincipalsList();
  },
  methods: {
    handelTabs() {
      this.pagination.page_num = 1;
      this.pagination.page_size = 10;
      this.search_form = {};
      this.list_key = new Date().getTime();
      switch (this.activeKey) {
        case "0":
          this.getOrderData();
          break;
        case "1":
          this.getScriptList();
          break;
        case "2":
          this.getProductList();
          break;
        case "3":
          this.getTemplateList();
          break;
      }
    },// 切换tabs
    getProductList() {
      this.loading = true;
      let params = {
        page: this.pagination.page_num,
        size: this.pagination.page_size,
        principalId: this.search_form.principalId,
        status: this.search_form.status,
        scriptName: this.search_form.scriptName,
        photographyName: this.search_form.photographyName,
        editorName: this.search_form.editorName,
        photoDateBegin: this.search_form.photoDateBegin,
        photoDateEnd: this.search_form.photoDateEnd,
        editDateBegin: this.search_form.editDateBegin,
        editDateEnd: this.search_form.editDateEnd,
        myTask: this.search_form.myTask,
        finishDateStart: this.search_form.finishDateStart,
        finishDateEnd: this.search_form.finishDateEnd,
      };
      for (let key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getProductList(params).then(res => {
        if (res.code == 200) {
          this.list = res.data.list || [];
          this.list = this.list.map(item => {
            item.script_name = item.scriptName.split(".")[0];
            return item;
          });
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取成片列表失败");
        }
        this.loading = false;
      });
    }, // 成片列表
    getScriptList() {
      this.loading = true;
      let params = {
        page: this.pagination.page_num,
        size: this.pagination.page_size,
        principalId: this.search_form.principalId,
        status: this.search_form.status,
        scriptName: this.search_form.scriptName,
        scriptDateBegin: this.search_form.scriptDateBegin,
        scriptDateEnd: this.search_form.scriptDateEnd,
        directorName: this.search_form.directorName,
        myTask: this.search_form.myTask,
        finishDateStart: this.search_form.finishDateStart,
        finishDateEnd: this.search_form.finishDateEnd,
      };
      for (let key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getScriptList(params).then(res => {
        if (res.code == 200) {
          this.list = res.data.list || [];
          this.list = this.list.map(item => {
            item.script_name = item.scriptName.split(".")[0];
            return item;
          });
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取脚本列表失败");
        }
        this.loading = false;
      });
    }, // 脚本列表
    getTemplateList() {
      this.loading = true;
      let params = {
        page: this.pagination.page_num,
        size: this.pagination.page_size,
        principalId: this.search_form.principalId,
        status: this.search_form.status,
        guideshootName: this.search_form.guideshootName,
        uploadDateStart: this.search_form.uploadDateStart,
        uploadDateEnd: this.search_form.uploadDateEnd,
        testDateStart: this.search_form.testDateStart,
        testDateEnd: this.search_form.testDateEnd,
        releaseDateStart: this.search_form.releaseDateStart,
        releaseDateEnd: this.search_form.releaseDateEnd,
        finishDateStart: this.search_form.finishDateStart,
        finishDateEnd: this.search_form.finishDateEnd,
      };
      for (let key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getTemplateList(params).then(res => {
        if (res.code == 200) {
          this.list = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取模板列表失败");
        }
        this.loading = false;
      });
    }, // 模板列表
    paginationChange(page_num, page_size) {
      this.pagination.page_num = page_num;
      this.pagination.page_size = page_size;
      switch (this.activeKey) {
        case "1":
          this.getScriptList();
          break;
        case "2":
          this.getProductList();
          break;
        case "3":
          this.getTemplateList();
          break;
      }
    },   // 处理分页
    loadList(type) {
      this.pagination.page_num = 1;
      this.pagination.page_size = 10;
      switch (type) {
        case "script":
          this.getScriptList();
          break;
        case "product":
          this.getProductList();
          break;
        case "template":
          this.getTemplateList();
          break;
      }
    },//更新列表
    loadedList(search_form, type) {
      this.search_form = search_form;
      this.pagination.page_num = 1;
      this.pagination.page_size = 10;
      switch (type) {
        case "script":
          this.getScriptList();
          break;
        case "product":
          this.getProductList();
          break;
        case "template":
          this.getTemplateList();
          break;
      }
    },//搜索重置更新列表
    getPrincipalsList() {
      _api.getPrincipalsList().then(res => {
        if (res.code == 200) {
          this.principal_list = res.data || [];
        } else {
          this.principal_list = [];
        }
      });
    }, // 品牌列表
  }
};
</script>

<style scoped lang="less">
.sticky[fixed] {
  padding-top: 90px;
}

.sticky-fixed {
  background: #fff;
}
</style>
